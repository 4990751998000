import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import useRole from '../../hooks/useRole';
import { useDeleteAtom } from '../../services/queries';
import { Atom } from '../../types/RealtaDocument';
import CreateEditAtomDialog from './CreateEditAtomDialog';

const AtomItem = ({ atom, codeBlockId }: { codeBlockId: string; atom: Atom }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: deleteAtom } = useDeleteAtom();
  const { isAdmin } = useRole();

  const [openEditAtomDialog, setOpenEditAtomDialog] = useState<{ open: boolean; isEdit: boolean }>({
    open: false,
    isEdit: false,
  });
  const [openDeleteAtomDialog, setOpenDeleteAtomDialog] = useState(false);

  const handleDeleteAtom = async () => {
    try {
      await deleteAtom(atom.uuid);
      setOpenDeleteAtomDialog(false);
      enqueueSnackbar('Atom deleted successfully');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to delete atom', { variant: 'error' });
    }
  };

  return (
    <>
      <Box border={1} p={1} key={atom.uuid}>
        <Box>Type: {atom.type}</Box>
        <Box>Name: {atom.name}</Box>
        <Box>Comment: {atom.comment}</Box>
        <Box display="flex" justifyContent="end" gap={1} mt={1}>
          <Button color="inherit" size="small" onClick={() => setOpenEditAtomDialog({ open: true, isEdit: false })}>
            Duplicate
          </Button>
          <Button color="inherit" size="small" onClick={() => setOpenEditAtomDialog({ open: true, isEdit: true })}>
            Edit
          </Button>
          <Button color="error" size="small" onClick={() => setOpenDeleteAtomDialog(true)}>
            Delete
          </Button>
        </Box>
      </Box>
      <CreateEditAtomDialog
        codeBlockId={codeBlockId}
        atom={atom}
        isEdit={openEditAtomDialog.isEdit}
        open={openEditAtomDialog.open}
        onClose={async () => setOpenEditAtomDialog({ open: false, isEdit: false })}
      />
      <Dialog open={openDeleteAtomDialog} onClose={() => setOpenDeleteAtomDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Delete Atom</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>Are you sure you want to delete this atom?</Box>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => setOpenDeleteAtomDialog(false)}>
            Cancel
          </Button>
          <Button autoFocus color="error" onClick={handleDeleteAtom} disabled={!isAdmin}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AtomItem;
