import AddIcon from '@mui/icons-material/Add';
import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useRole from '../../hooks/useRole';
import useUsername from '../../hooks/useUsername';
import { documentService } from '../../services/DocumentService';
import { addTest, selectTest, testSelectors } from '../../store/test';
import { TestCaseStatus } from '../../store/testCase';
import { Test } from '../../types/RealtaDocument';
import { TestCaseStatusIcon } from '../old-version/ui/TestCaseStatusIcon/TestCaseStatusIcon';
import { ToolbarIconButton } from '../old-version/ui/ToolbarIconButton/ToolbarIconButton';
import { TestCaseListToolbar } from './TestCaseListToolbar';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  item: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: grey[300],
      boxShadow: `rgba(0, 0, 0, 0.2) 1px 3px 5px -1px`,
    },
    '&:focus': {
      backgroundColor: grey[300],
      boxShadow: `rgba(0, 0, 0, 0.2) 1px 3px 5px -1px`,
    },
    outlineOffset: '0px',
    outline: 'none',
  },
  statusIcon: {
    marginRight: '20px',
    marginTop: '5px',
  },
  titlePlaceholder: {
    color: grey[600],
    fontStyle: 'italic',
  },
  selectedItem: {
    backgroundColor: 'rgba(177, 255, 240, 0.40)',
    fontWeight: 600,
  },
});

type TestCaseListPanelProps = {
  tests: Test[];
  sectionId: string;
};

const TestCaseListPanel: React.FC<TestCaseListPanelProps> = ({ tests, sectionId }) => {
  const classes = useStyles();
  const { isReadOnly } = useRole();
  const username = useUsername();

  const dispatch = useDispatch();
  const activeId = useSelector(testSelectors.selectedTestId);

  const handleItemClick = (id: string) => {
    if (id === activeId) {
      return;
    }
    dispatch(selectTest(id));
  };

  const item = ({ name, uuid, status, createdBy }: Test) => (
    <div
      className={classNames(classes.item, {
        [classes.selectedItem]: uuid === activeId,
      })}
      title="Click to open"
      tabIndex={0}
      key={uuid}
      onClick={() => handleItemClick(uuid)}
    >
      <div className={classes.statusIcon}>
        <TestCaseStatusIcon status={status as TestCaseStatus} />
      </div>
      <div>
        <span>{name ? <span>{name}</span> : <span className={classes.titlePlaceholder}>Untitled test case</span>}</span>
        {!!createdBy && (
          <Typography variant="body2" fontSize={12}>
            Created by: {createdBy}
          </Typography>
        )}
      </div>
    </div>
  );

  const handleCreateNew = async () => {
    const newTest = await documentService.createTest({
      name: 'Untitled test case',
      description: '',
      parentNode: sectionId,
      createdBy: username,
    });
    newTest && dispatch(addTest(newTest));
  };

  const noTestCaseMessage = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '30px',
        lineHeight: '2',
      }}
    >
      <span>No test cases found.</span>
      <span>Create new test case.</span>
      <ToolbarIconButton aria-label="Create new" title="Create new" onClick={handleCreateNew} disabled={isReadOnly}>
        <AddIcon fontSize="small" />
      </ToolbarIconButton>
    </div>
  );

  return (
    <Box width="100%">
      <TestCaseListToolbar onCreate={handleCreateNew} />
      <div
        style={{
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          paddingBottom: '10px',
        }}
      >
        {tests.length > 0 ? tests.map((test: Test) => item(test)) : noTestCaseMessage}
      </div>
    </Box>
  );
};

export { TestCaseListPanel };
