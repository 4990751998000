import GetAppIcon from '@mui/icons-material/GetApp';
import React from 'react';

import { ToolbarIconButton } from '../old-version/ui/ToolbarIconButton/ToolbarIconButton';

type ExportCodeBlockMenuButtonProps = {
  rule: string;
};

const ExportCodeBlockMenuButton: React.FC<ExportCodeBlockMenuButtonProps> = ({ rule }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<any>(null);

  const handleDownloadText = (event: any) => {
    event.stopPropagation();
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(rule));
    element.setAttribute('download', 'rules.txt');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    handleClose();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <ToolbarIconButton
      aria-label="Download menu"
      onClick={handleDownloadText}
      title="Download"
      ref={anchorRef}
      disabled={!rule}
    >
      <GetAppIcon fontSize="small" />
    </ToolbarIconButton>
  );
};

export { ExportCodeBlockMenuButton };
