import { makeStyles } from '@mui/styles';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { testSelectors } from '../../store/test';
import { ResultsToolbar } from '../old-version/ui/ResultsToolbar/ResultsToolbar';
import { ResultsOutput } from './ResultsOutput';

const useStyles = makeStyles({
  resultsPanel: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
});

const TestCaseResultsPanel: React.FC = () => {
  const classes = useStyles();
  const controls = useAnimation();
  const activeTestCaseId = useSelector(testSelectors.selectedTest);

  useEffect(() => {
    controls.set({ opacity: 0 });
    controls.start({ opacity: 1, transition: { duration: 2 } });
  }, [activeTestCaseId]);

  return (
    <motion.article className={classes.resultsPanel} animate={controls}>
      <ResultsToolbar />
      <ResultsOutput />
    </motion.article>
  );
};

export { TestCaseResultsPanel };
