import { navigate } from '@gatsbyjs/reach-router';
import { AppBar, Box, Switch, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { documentService } from '../../services/DocumentService';
import { DocumentNode } from '../../types/RealtaDocument';
import { generateDocumentUrl } from '../../utils/document-url';
import { getMsalInstance } from '../../utils/get-msal-instance';
import CodeBlockGrid from '../CodeBlock/CodeBlockGrid';
import GraphView from '../Graph/GraphView';
import TestSection from '../TestSection/TestSection';
import CommentSection from './CommentSection';
import DocumentHeader from './DocumentHeader';
import PdfView from './PdfView';
import SearchSection from './SearchSection';
import TOC from './TOC';
import { useViewMode } from './ViewModeProvider';

const DocumentDetails: React.FC<{
  documentUuid: string;
}> = ({ documentUuid }) => {
  const firstUpdate = useRef(true);

  const queryParams = new URLSearchParams(location.search);
  const msalInstance = getMsalInstance();
  const account = msalInstance.getActiveAccount();

  const { data: document, isFetching } = useQuery(
    ['getDocument'],
    async () => {
      return await documentService.getDocument({ uuid: documentUuid, roles: account?.idTokenClaims?.roles });
    },
    {
      staleTime: 1000 * 60,
      enabled: !!documentUuid,
    }
  );

  const { viewMode } = useViewMode();

  const [pdfSize, setPdfSize] = useState<{ width: number | undefined; height: number | undefined }>({
    width: undefined,
    height: undefined,
  });

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [selectedNode, setSelectedNode] = useState<DocumentNode | undefined | null>(null);
  const [showAnnotations, setShowAnnotations] = useState<boolean>(true);

  const [graphSize, setGraphSize] = useState<{ width: number; height: number }>({ width: 500, height: 200 });

  const page = useMemo(() => {
    const page = queryParams.get('page');
    return page ? parseInt(page) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.get('page')]);

  const sectionId = useMemo(
    () => queryParams.get('section-id'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryParams.get('section-id')]
  );

  // Update the URL when the page changes
  // This is to make sure the URL is always in sync with the current page
  // This hooks is only called after the first render, to make sure its not overlapping with the hook below for reading the section in the first render
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    navigate(
      generateDocumentUrl({
        documentId: documentUuid,
        page: page || 1,
        view: queryParams.get('view') || undefined,
      }),
      {
        replace: true,
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // Read the section from the URL and set the selected node
  // This is to make sure the selected node is always in sync with the URL
  useEffect(() => {
    const init = async () => {
      if (sectionId) {
        const sectionNode = await documentService.getSection({ uuid: sectionId });
        setSelectedNode(sectionNode);
        if (sectionNode && sectionNode?.startBoundryPage) {
          const validPage = page || sectionNode.startBoundryPage;
          navigate(
            generateDocumentUrl({
              documentId: documentUuid,
              sectionId: sectionId,
              page: validPage,
              view: queryParams.get('view') || undefined,
            }),
            {
              replace: true,
            }
          );
          return;
        }
      } else {
        setSelectedNode(null);
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionId, documentUuid]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setGraphSize({
        // NOTE: 16 is the padding of the container
        width: container.clientWidth - 16,
        // NOTE: 16 is the padding of the container and 48 is the height of the header
        height: container.clientHeight - 48,
      });
    }
  }, [containerRef.current?.clientHeight, containerRef.current?.clientWidth]);

  if (!document) {
    if (isFetching) {
      return <div>Loading...</div>;
    } else {
      navigate('/documents');
      return <div>Redirecting...</div>;
    }
  }

  return (
    // 2 levels of box prevent gatsby losses the style of the outer box when reload the page
    <Box>
      <Box display="flex">
        <Box
          maxHeight="calc(100vh - 48px)"
          flexShrink={0}
          overflow="hidden"
          position="relative"
          display="flex"
          flexDirection="column"
        >
          <AppBar
            position="static"
            sx={{
              bgcolor: grey[300],
              pr: 1,
              color: '#000',
              height: 48,
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
              borderRight: '1px solid black',
              width: pdfSize.width,
            }}
          >
            <Box display="flex" alignItems="center" gap={1} flexGrow={1}>
              <TOC documentUuid={documentUuid} />
              <Typography fontSize={14}>{document?.displayName}</Typography>
            </Box>
            <Box display="flex" gap={1}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  fontSize: '12px !important',
                  alignItems: 'center',
                  flexShrink: 0,
                }}
              >
                <Switch
                  size="small"
                  checked={showAnnotations}
                  onChange={() => setShowAnnotations(!showAnnotations)}
                  color="secondary"
                />
                <Typography fontSize={10} whiteSpace="nowrap">
                  Show Annotations
                </Typography>
              </Box>
            </Box>
          </AppBar>
          <PdfView
            documentUuid={documentUuid}
            node={selectedNode}
            page={page || 1}
            showAnnotations={showAnnotations}
            setPdfSize={setPdfSize}
            pdfSize={pdfSize}
          />
        </Box>
        <Box flexGrow={1} height="calc(100vh - 48px)" ref={containerRef} overflow="hidden">
          {sectionId && <DocumentHeader documentUuid={documentUuid} sectionUuid={sectionId} />}
          {viewMode === 'Search' ? (
            <SearchSection documentUuid={documentUuid} />
          ) : selectedNode ? (
            <Box height="calc(100vh - 100px)" bgcolor="#fdfdfd">
              {viewMode === 'CodeBlock' && <CodeBlockGrid node={selectedNode} shortName={document?.shortName} />}
              {viewMode === 'References' && (
                <React.Suspense fallback={<div>Loading...</div>}>
                  <GraphView size={graphSize} node={selectedNode} document={document} />
                </React.Suspense>
              )}
              {viewMode === 'Comment' && <CommentSection documentUuid={documentUuid} node={selectedNode} />}
              {viewMode === 'Test' && <TestSection node={selectedNode} />}
            </Box>
          ) : (
            <Box height="calc(100vh - 100px)" p={1} display="flex" justifyContent="center" alignItems="center">
              <Typography variant="h5">Please choose a section to view...</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default DocumentDetails;
