import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';

import { testSelectors } from '../../store/test';
import { RatTestCodeMirror } from './RatTestCodeMirror';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
}));

const spinnerStyle = {
  position: 'absolute',
  left: '40%',
  top: '10%',
};

const ResultsOutput: React.FC = () => {
  const classes = useStyles();
  const result = useSelector(testSelectors.selectedTest)?.result || '';
  const wordWrap = useSelector(testSelectors.wordWrap);

  const isRunning = false;

  const options: any = {
    lineNumbers: true,
    lineWrapping: true,
    readOnly: true,
    cursorHeight: 0,
    mode: 'turnipMode',
  };

  return (
    <div className={classes.root}>
      {isRunning ? (
        <CircularProgress color="secondary" size="4rem" sx={spinnerStyle} />
      ) : (
        <RatTestCodeMirror options={options} value={result} readOnly={false} lineWrapping={wordWrap} />
      )}
    </div>
  );
};

export { ResultsOutput };
