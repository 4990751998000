import { TextSnippet } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, CircularProgress, Typography } from '@mui/material';
import { Link } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import uniqolor from 'uniqolor';

import { commentService } from '../../services/CommentService';
import { documentService } from '../../services/DocumentService';
import { CommentCount } from '../../types/Comment';
import { DocumentNode, RealtaDocument } from '../../types/RealtaDocument';
import { generateDocumentUrl } from '../../utils/document-url';
import CommentCountItem from './CommentCountItem';
import DocumentItem from './DocumentItem';

const DocumentListItem: React.FC<{ document: RealtaDocument; loadComment: boolean }> = ({ document, loadComment }) => {
  const [headingSections, setHeadingSections] = useState<DocumentNode[]>([]);
  const [expandedAccordion, setExpandedAccordion] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [commentCount, setCommentCount] = useState<CommentCount | undefined>();
  const [subDocumentCommentCounts, setSubDocumentCommentCounts] = useState<CommentCount[]>([]);

  useEffect(() => {
    const init = async () => {
      if (loadComment) {
        setLoading(true);
        const commentCount = await commentService.countCommentsOfDocument(document.uuid);
        setCommentCount(commentCount);
        setLoading(false);
      }
    };

    init();
  }, [document, loadComment]);

  const onClickSummary = useCallback(
    async (documentUuid: string) => {
      setLoading(true);
      if (expandedAccordion === documentUuid) {
        setExpandedAccordion(null);
      } else {
        setExpandedAccordion(documentUuid);
        setHeadingSections(await documentService.getDocumentHeadingSections({ documentUuid }));
        const commentCounts = await documentService.getNodeSubSectionComments({ uuid: documentUuid, isDocument: true });
        setSubDocumentCommentCounts(commentCounts);
      }
      setLoading(false);
    },
    [expandedAccordion]
  );

  return (
    <Accordion
      key={document.uuid}
      expanded={expandedAccordion === document.uuid}
      onChange={() => onClickSummary(document.uuid)}
      sx={{ my: 1 }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          component={Link}
          to={generateDocumentUrl({ documentId: document.uuid })}
          sx={{ textDecoration: 'none', color: '#000', width: '100%', mr: 2 }}
        >
          <Box display="flex" gap={2} width="100%">
            <Box>
              <TextSnippet fontSize="large" />
            </Box>
            <Box flexGrow={1}>
              <Box display="flex" gap={2} width="100%">
                <Box flexGrow={1}>
                  <Typography fontWeight="bold">{document.displayName}</Typography>
                </Box>
                <Box display="flex" gap={0.5}>
                  {document.labels?.map((label, i) => (
                    <Chip
                      key={i}
                      label={label}
                      sx={{
                        bgcolor: uniqolor(label, {
                          saturation: [35, 70],
                          lightness: 80,
                        }).color,
                      }}
                    />
                  ))}
                </Box>
              </Box>
              <Box display="flex" gap={0.5}>
                {document.jdx?.filter(Boolean).map((j, i) => (
                  <Chip
                    key={i}
                    label={j}
                    sx={{
                      bgcolor: '#fff',
                      border: '1px solid black',
                      fontSize: '11px',
                      height: '28px',
                      px: 0,
                    }}
                  />
                ))}
              </Box>
              {loadComment && commentCount && (
                <Box mt={1}>
                  <CommentCountItem commentCount={commentCount} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {loading ? (
          <Box textAlign="center">
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          headingSections.map((section) => (
            <DocumentItem
              key={section.uuid}
              node={section}
              documentId={document.uuid}
              loadComment={loadComment}
              commentCount={subDocumentCommentCounts.find((c) => c.id === section.uuid)}
            />
          ))
        )}
      </AccordionDetails>
    </Accordion>
  );
};
export default DocumentListItem;
