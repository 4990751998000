import React, { ReactNode, createContext, useContext, useState } from 'react';

import { View } from '../../types/ViewMode';

type ViewModeContextValue = {
  viewMode: View;
  setViewMode: (viewMode: View) => void;
  showChangeAlert: boolean;
  setShowChangeAlert: (showChangeAlert: boolean) => void;
} | null;

export const ViewModeContext = createContext<ViewModeContextValue>(null);

export default function ViewModeProvider({ children }: { children: ReactNode }) {
  const queryParams = new URLSearchParams(location.search);

  const [viewMode, setViewMode] = useState<View>((queryParams.get('view') as View) || 'References');
  const [showChangeAlert, setShowChangeAlert] = useState<boolean>(false);

  return (
    <ViewModeContext.Provider value={{ viewMode, setViewMode, showChangeAlert, setShowChangeAlert }}>
      {children}
    </ViewModeContext.Provider>
  );
}

export const useViewMode = () => {
  const context = useContext(ViewModeContext);
  if (!context) {
    throw new Error('useUser must be used within a ViewModeProvider');
  }
  return { viewMode: context.viewMode, setViewMode: context.setViewMode };
};

export const useChangeAlert = () => {
  const context = useContext(ViewModeContext);
  if (!context) {
    throw new Error('useUser must be used within a ViewModeProvider');
  }
  return { showChangeAlert: context.showChangeAlert, setShowChangeAlert: context.setShowChangeAlert };
};
