import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import SaveIcon from '@mui/icons-material/Save';
import WrapTextIcon from '@mui/icons-material/WrapText';
import { Box, Checkbox, IconButton, Tooltip, Typography } from '@mui/material';
import grey from '@mui/material/colors/grey';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import useRole from '../../hooks/useRole';
import { CodeBlock } from '../../types/RealtaDocument';
import { ClipboardIcon } from '../General/ClipboardIcon';
import { ToolbarIconButton } from '../old-version/ui/ToolbarIconButton/ToolbarIconButton';
import { ToolbarToggleButton } from '../old-version/ui/ToolbarToggleButton/ToolbarToggleButton';
import { ExportCodeBlockMenuButton } from './ExportCodeBlockMenuButton';

const CodeBlockToolbar = ({
  codeBlock,
  hightLight,
  setHightLight,
  lineWrapping,
  setLineWrapping,
  generateCodeBlock,
  saveCodeBlock,
  onApproveCodeBlock,
  onApproveAtom,
}: {
  codeBlock: CodeBlock;
  hightLight: boolean;
  setHightLight: Function;
  lineWrapping: boolean;
  setLineWrapping: Function;
  generateCodeBlock: () => void;
  saveCodeBlock: () => void;
  onApproveCodeBlock: (isApproved: boolean) => Promise<void>;
  onApproveAtom: (isApproved: boolean) => Promise<void>;
}) => {
  const { isReadOnly, isReviewer } = useRole();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCodeBlockApproved, setIsCodeBlockApproved] = useState(!!codeBlock.codeApproved);
  const [isAtomApproved, setIsAtomApproved] = useState(!!codeBlock.atomsApproved);

  const handleCopy = () => {
    const el = document.createElement('textarea');
    el.value = codeBlock.rule;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    enqueueSnackbar('Copied to clipboard', { variant: 'success' });
  };

  const handleReviewCodeBlock = async () => {
    setIsLoading(true);
    try {
      const newStatus = !isCodeBlockApproved;
      await onApproveCodeBlock(newStatus);
      setIsCodeBlockApproved(newStatus);
      enqueueSnackbar(newStatus ? 'Code block is approved' : 'Code block is not approved');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to review code block', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleReviewAtoms = async () => {
    setIsLoading(true);
    try {
      const newStatus = !isAtomApproved;
      await onApproveAtom(newStatus);
      setIsAtomApproved(newStatus);
      enqueueSnackbar(newStatus ? 'Atoms are approved' : 'Atoms are not approved');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to review atom', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box height={58} minHeight={58} bgcolor={grey[200]} p={1} display="flex" justifyContent="space-between">
        <Box>
          {!!codeBlock.createdBy && (
            <Typography variant="body2" fontSize={12}>
              Created by: {codeBlock.createdBy}
            </Typography>
          )}
          {!!codeBlock.updatedBy && (
            <Typography variant="body2" fontSize={12}>
              Updated by: {codeBlock.updatedBy}
            </Typography>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          {isReviewer && (
            <>
              <Tooltip title="Approve Code Block" placement="top">
                <Checkbox
                  checked={isCodeBlockApproved}
                  onChange={handleReviewCodeBlock}
                  color="secondary"
                  disabled={isLoading}
                />
              </Tooltip>
              <Tooltip title="Approve Atoms" placement="top">
                <Checkbox
                  checked={isAtomApproved}
                  onChange={handleReviewAtoms}
                  color="secondary"
                  disabled={isLoading}
                />
              </Tooltip>
            </>
          )}
          <IconButton
            onClick={saveCodeBlock}
            title="Save Code Block"
            sx={{
              margin: '0',
              border: 'none',
              marginLeft: '3px !important',
              color: '#000 !important',
              height: '30px',
              padding: '0px 4px',
            }}
            disabled={isReadOnly}
          >
            <SaveIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={generateCodeBlock}
            title="Re-generate Code Block"
            sx={{
              margin: '0 3px',
              border: 'none',
              marginLeft: '3px !important',
              color: '#000 !important',
              height: '30px',
              padding: '0px 4px',
            }}
            disabled={isReadOnly}
          >
            <ChangeCircleOutlinedIcon fontSize="small" />
          </IconButton>
          <ToolbarToggleButton
            value="syntax"
            selected={hightLight}
            onChange={() => setHightLight(!hightLight)}
            title={hightLight ? 'Syntax highlight off' : 'Syntax highlight on'}
          >
            <FormatColorTextIcon fontSize="small" />
          </ToolbarToggleButton>
          <ToolbarToggleButton
            value="wrap"
            selected={lineWrapping}
            onChange={() => setLineWrapping(!lineWrapping)}
            title={lineWrapping ? 'Word wrap off' : 'Word wrap on'}
          >
            <WrapTextIcon fontSize="small" />
          </ToolbarToggleButton>
          <ToolbarIconButton
            aria-label="copy rules to clipboard"
            onClick={handleCopy}
            disabled={!codeBlock.rule}
            title="Copy to clipboard"
          >
            <ClipboardIcon />
          </ToolbarIconButton>
          <ExportCodeBlockMenuButton rule={codeBlock.rule} />
        </Box>
      </Box>
    </>
  );
};

export default CodeBlockToolbar;
