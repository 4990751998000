import { Box, Card, CircularProgress, Typography } from '@mui/material';
import React from 'react';

import { useGetTags } from '../../services/queries';
import { DocumentNode } from '../../types/RealtaDocument';
import AddTagButton from './AddTagButton';
import TagItem from './TagItem';

const Tags: React.FC<{
  documentNode: DocumentNode;
}> = ({ documentNode }) => {
  const { data: tags, isLoading } = useGetTags(documentNode.uuid);

  return (
    <Card sx={{ p: 2, mx: 1, mt: 1, mb: 2 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" fontWeight="bold" mb={2}>
          Tags
        </Typography>
        <AddTagButton nodeUuid={documentNode.uuid} />
      </Box>
      {isLoading && (
        <Box display="flex" justifyContent="center" py={1}>
          <CircularProgress color="secondary" />
        </Box>
      )}
      {tags?.length ? (
        <Box display="flex" gap={0.5}>
          {tags.map((tag) => (
            <TagItem key={tag.uuid} data={tag} nodeUuid={documentNode.uuid} />
          ))}
        </Box>
      ) : (
        <Typography variant="body1">No tags found</Typography>
      )}
    </Card>
  );
};
export default Tags;
