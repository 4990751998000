import { Box } from '@mui/material';
import React from 'react';

import SearchComponent from '../SearchPage/SearchComponent';

const SearchSection: React.FC<{ documentUuid: string }> = ({ documentUuid }) => {
  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      bgcolor="transparent"
      gap={3}
      p={2}
      overflow="auto"
    >
      <SearchComponent documentUuid={documentUuid} />
    </Box>
  );
};
export default SearchSection;
