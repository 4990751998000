import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useRole from '../../hooks/useRole';
import { testSelectors } from '../../store/test';
import { Test } from '../../types/RealtaDocument';
import { ExpandablePanel } from '../old-version/ui/ExpandablePanel/ExpandablePanel';
import { DescriptionInput } from './DescriptionInput';
import { FactsInput } from './FactsInput';
import { RequiredResultsInput } from './RequiredResultsInput';
import { StartNodeInput } from './StartNodeInput';
import { TestCaseToolbar } from './TestCaseToolbar';
import { UnwantedResultsInput } from './UnwantedResultsInput';

const useStyles = makeStyles({
  inputsContainer: {
    overflowY: 'auto',
    height: `calc(100vh - 88px)`,
    backgroundColor: grey[100],
    paddingTop: '3px',
  },
});

const TestCaseInputPanel: React.FC<{ onSave?: (test: Test) => void; onTestsChange?: () => void }> = ({
  onSave,
  onTestsChange,
}) => {
  const classes = useStyles();
  const controls = useAnimation();
  const { isReadOnly } = useRole();
  const [descriptionCollapsed, setDescriptionCollapsed] = useState(false);
  const [startNodeCollapsed, setStartNodeCollapsed] = useState(false);
  const [factsCollapsed, setFactsCollapsed] = useState(false);
  const [requiredResultsCollapsed, setRequiredResultsCollapsed] = useState(false);
  const [unwantedResultsCollapsed, setUnwantedResultsCollapsed] = useState(false);

  const activeTestCaseId = useSelector(testSelectors.selectedTestId) || '';

  const handleDescToggle = (value: boolean) => {
    setDescriptionCollapsed(value);
  };

  const handleStartNodeToggle = (value: boolean) => {
    setStartNodeCollapsed(value);
  };

  const handleFactsToggle = (value: boolean) => {
    setFactsCollapsed(value);
  };

  const handleRequiredResultsToggle = (value: boolean) => {
    setRequiredResultsCollapsed(value);
  };

  const handleUnwantedResultsToggle = (value: boolean) => {
    setUnwantedResultsCollapsed(value);
  };

  useEffect(() => {
    controls.set({ opacity: 0 });
    controls.start({ opacity: 1, transition: { duration: 2 } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTestCaseId]);

  return (
    <motion.div animate={controls}>
      <TestCaseToolbar onSave={onSave} />
      <div className={classes.inputsContainer}>
        <ExpandablePanel heading="Description" isCollapsed={descriptionCollapsed} onToggleCollapse={handleDescToggle}>
          <DescriptionInput hidden={descriptionCollapsed} isReadOnly={isReadOnly} onChange={onTestsChange} />
        </ExpandablePanel>

        <ExpandablePanel heading="Start node" isCollapsed={startNodeCollapsed} onToggleCollapse={handleStartNodeToggle}>
          <StartNodeInput hidden={startNodeCollapsed} isReadOnly={isReadOnly} onChange={onTestsChange} />
        </ExpandablePanel>

        <ExpandablePanel heading="Facts" isCollapsed={factsCollapsed} onToggleCollapse={handleFactsToggle}>
          <FactsInput isReadOnly={isReadOnly} onChange={onTestsChange} />
        </ExpandablePanel>

        <ExpandablePanel
          heading="Required results"
          isCollapsed={requiredResultsCollapsed}
          onToggleCollapse={handleRequiredResultsToggle}
        >
          <RequiredResultsInput isReadOnly={isReadOnly} onChange={onTestsChange} />
        </ExpandablePanel>

        <ExpandablePanel
          heading="Unwanted results"
          isCollapsed={unwantedResultsCollapsed}
          onToggleCollapse={handleUnwantedResultsToggle}
        >
          <UnwantedResultsInput isReadOnly={isReadOnly} onChange={onTestsChange} />
        </ExpandablePanel>
      </div>
    </motion.div>
  );
};

export { TestCaseInputPanel };
