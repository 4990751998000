import { navigate } from '@gatsbyjs/reach-router';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, LinearProgress, Typography } from '@mui/material';
import React, { memo, useCallback, useState } from 'react';

import { documentService } from '../../services/DocumentService';
import { CommentCount } from '../../types/Comment';
import { DocumentNode } from '../../types/RealtaDocument';
import { generateDocumentUrl } from '../../utils/document-url';
import CommentCountItem from './CommentCountItem';

const DocumentItem: React.FC<{
  documentId: string;
  node: DocumentNode;
  loadComment: boolean;
  commentCount?: CommentCount;
}> = ({ node, documentId, loadComment, commentCount }) => {
  const [expandedSectionAccordion, setExpandedSectionAccordion] = useState<string | null>(null);
  const [subSections, setSubSections] = useState<DocumentNode[]>([]);
  const [commentCounts, setCommentCounts] = useState<CommentCount[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchSections = useCallback(async () => {
    setIsLoading(true);
    const sections = await documentService.getSubSectionsOfSection({ sectionUuid: node.uuid });
    setSubSections(sections);
    const commentCounts = await documentService.getNodeSubSectionComments({ uuid: node.uuid, isDocument: false });
    setCommentCounts(commentCounts);
    setIsLoading(false);
  }, [node.uuid]);

  const onClickSection = async (sectionUuid: string) => {
    if (expandedSectionAccordion === sectionUuid) {
      setExpandedSectionAccordion(null);
    } else {
      setExpandedSectionAccordion(sectionUuid);
      await fetchSections();
    }
  };

  const onClick = (n: DocumentNode) => {
    if (n.startBoundryPage) {
      const url = generateDocumentUrl({ documentId, sectionId: node.uuid, page: node.startBoundryPage });
      navigate(url);
    }
  };

  return (
    <Accordion
      key={node.uuid}
      expanded={expandedSectionAccordion === node.uuid}
      onChange={() => onClickSection(node.uuid)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box onClick={() => onClick(node)} sx={{ textDecoration: 'none', color: '#000' }}>
          <Box>
            <Typography fontWeight={500}>{node.fullId || 'Unnamed'}</Typography>
            {loadComment && commentCount && (
              <Box>
                <CommentCountItem commentCount={commentCount} />
              </Box>
            )}
          </Box>
        </Box>
      </AccordionSummary>
      {isLoading && <LinearProgress color="secondary" />}
      {!!subSections.length && (
        <AccordionDetails>
          {subSections.map((d) => (
            <DocumentItem
              key={d.uuid}
              node={d}
              documentId={documentId}
              loadComment={loadComment}
              commentCount={commentCounts.find((c) => c.id === d.uuid)}
            />
          ))}
        </AccordionDetails>
      )}
    </Accordion>
  );
};
export default memo(DocumentItem);
