import { useLocation } from '@gatsbyjs/reach-router';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Snackbar,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import sizeMe, { SizeMeProps } from 'react-sizeme';
import { useBeforeUnload } from 'react-use';

import useRole from '../../hooks/useRole';
import useUsername from '../../hooks/useUsername';
import { documentService } from '../../services/DocumentService';
import { useGetCodeGenDebug } from '../../services/queries';
import { CodeBlock, DocumentNode } from '../../types/RealtaDocument';
import { useChangeAlert } from '../DocumentPage/ViewModeProvider';
import { SplitPane } from '../old-version/ui/SplitPane/SplitPane';
import { AtomPanel } from './AtomPanel';
import { AtomPanelClosed } from './AtomPanelClosed';
import CodeBlockInput from './CodeBlockInput';
import CodeBlockToolbar from './CodeBlockToolbar';
import { CodeGenDebugPanel } from './CodeGenDebugPanel';
import { CodeGenDebugPanelClosed } from './CodeGenDebugPanelClosed';

type CodeBlockGridProps = {
  size: SizeMeProps['size'];
  node: DocumentNode;
  shortName?: string;
};

const CodeBlockGrid: React.FC<CodeBlockGridProps> = ({ node, shortName }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const documentId = queryParams.get('document-id') || '';
  const { isReadOnly } = useRole();
  const username = useUsername();
  const { enqueueSnackbar } = useSnackbar();

  const { setShowChangeAlert } = useChangeAlert();

  const [selectedCodeBlock, setSelectedCodeBlock] = useState<CodeBlock | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hightLight, setHightLight] = useState<boolean>(true);
  const [lineWrapping, setLineWrapping] = useState<boolean>(true);
  const [newDocumentShortName, setNewDocumentShortName] = useState<string>(shortName || '');
  const [openDialog, setOpenDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [open, setOpen] = React.useState(false);
  const [rule, setRule] = React.useState('');
  const [openAtomPanel, setOpenAtomPanel] = React.useState(false);
  const [openCodeGenDebugPanel, setOpenCodeGenDebugPanel] = React.useState(false);

  const { data: codeGenDebug, isFetching: isFetchingCodeGenDebug } = useGetCodeGenDebug(selectedCodeBlock?.uuid || '', {
    enabled: !!selectedCodeBlock?.uuid,
  });

  useEffect(() => {
    const getCodeBlock = async () => {
      setIsLoading(true);
      try {
        const codeblock = await documentService.getCodeBlockByNodeId({ parentNodeUuid: node.uuid });
        setSelectedCodeBlock(codeblock);
        setRule(codeblock?.rule || '');
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Failed to get code block', { variant: 'error' });
      }
      setIsLoading(false);
    };

    getCodeBlock();
  }, [node]);

  useBeforeUnload(!!rule && rule !== selectedCodeBlock?.rule, 'Changes you made may not be saved.');

  const createCodeBlock = async () => {
    try {
      setIsLoading(true);
      const codeBlock = await documentService.createCodeBlock({
        rule: '',
        parentNode: node.uuid,
        createdBy: username,
      });
      if (codeBlock) {
        setSelectedCodeBlock(codeBlock);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to create code block', { variant: 'error' });
    }
    setIsLoading(false);
  };

  const handleRuleChange = (value: string) => {
    setShowChangeAlert(value !== selectedCodeBlock?.rule);
    setRule(value);
  };

  const generateCodeBlock = async () => {
    if (!newDocumentShortName) {
      setOpenDialog(true);
      return;
    }
    try {
      setIsLoading(true);
      if (selectedCodeBlock) {
        const newRule = await documentService.generateCodeBlockData({
          documentShortName: newDocumentShortName,
          documentUuid: documentId,
          uuid: node.uuid,
        });
        setRule(newRule);
        if (newRule !== selectedCodeBlock.rule) {
          setShowChangeAlert(true);
        }
      } else {
        const codeBlock = await documentService.createCodeBlock({
          rule: await documentService.generateCodeBlockData({
            documentShortName: newDocumentShortName,
            documentUuid: documentId,
            uuid: node.uuid,
          }),
          parentNode: node.uuid,
          createdBy: username,
        });
        if (codeBlock) {
          setSelectedCodeBlock(codeBlock);
          setRule(codeBlock.rule || '');
        }
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to generate code block', { variant: 'error' });
    }
    setIsLoading(false);
  };

  const saveCodeBlock = async () => {
    if (!selectedCodeBlock) return;
    const value = rule;
    const newCodeBlock = await documentService.updateCodeBlock({
      uuid: selectedCodeBlock.uuid,
      rule: value,
      updatedBy: username,
    });
    setSelectedCodeBlock(newCodeBlock);
    setShowChangeAlert(false);

    setOpen(true);
  };

  const approveCodeBlock = async (isApproved: boolean) => {
    if (!selectedCodeBlock) return;
    const newCodeBlock = await documentService.reviewCodeBlock({
      uuid: selectedCodeBlock.uuid,
      codeApproved: isApproved,
    });
    setSelectedCodeBlock(newCodeBlock);
  };

  const approveAtom = async (isApproved: boolean) => {
    if (!selectedCodeBlock) return;
    const newCodeBlock = await documentService.reviewCodeBlock({
      uuid: selectedCodeBlock.uuid,
      atomsApproved: isApproved,
    });
    setSelectedCodeBlock(newCodeBlock);
  };

  const handleAddShortNameAndGenerateCodeBlock = async () => {
    try {
      if (!newDocumentShortName) {
        setErrorMessage('Document Short Name is required');
        return;
      }
      await documentService.updateDocument({ uuid: documentId, shortName: newDocumentShortName });
      await generateCodeBlock();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to add short name', { variant: 'error' });
    }

    setOpenDialog(false);
  };

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = async (event: any) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault();
        await saveCodeBlock();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedCodeBlock, rule]);

  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return (
    <>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} message="Code Block is updated" />
      {selectedCodeBlock ? (
        <Box boxShadow={5} height="100%" display="flex" flexDirection="column" p={1}>
          <CodeBlockToolbar
            codeBlock={selectedCodeBlock}
            hightLight={hightLight}
            lineWrapping={lineWrapping}
            setHightLight={setHightLight}
            setLineWrapping={setLineWrapping}
            generateCodeBlock={generateCodeBlock}
            saveCodeBlock={saveCodeBlock}
            onApproveAtom={approveAtom}
            onApproveCodeBlock={approveCodeBlock}
          />
          <Box
            // 50px is the height of the toolbar without padding
            height="calc(100% - 58px)"
          >
            <SplitPane
              split="vertical"
              hoverDisabled={!openCodeGenDebugPanel}
              primary={openCodeGenDebugPanel ? 'first' : 'second'}
              allowResize={openCodeGenDebugPanel}
              size={openCodeGenDebugPanel ? '72%' : '35px'}
              style={{
                position: 'relative',
              }}
            >
              <SplitPane
                split="vertical"
                hoverDisabled={!openAtomPanel}
                primary={openAtomPanel ? 'first' : 'second'}
                allowResize={openAtomPanel}
                size={openAtomPanel ? '66%' : '35px'}
                style={{
                  position: 'relative',
                }}
              >
                <CodeBlockInput
                  nodeId={node.uuid}
                  rule={rule}
                  hightLight={hightLight}
                  lineWrapping={lineWrapping}
                  readOnly={isReadOnly}
                  onRuleChange={handleRuleChange}
                />
                {openAtomPanel ? (
                  <AtomPanel onClosePanel={() => setOpenAtomPanel(false)} codeBlockId={selectedCodeBlock.uuid} />
                ) : (
                  <AtomPanelClosed onOpenPanel={() => setOpenAtomPanel(true)} />
                )}
              </SplitPane>
              {openCodeGenDebugPanel ? (
                <CodeGenDebugPanel
                  onClosePanel={() => setOpenCodeGenDebugPanel(false)}
                  codeGenDebug={codeGenDebug || null}
                  isFetching={isFetchingCodeGenDebug}
                />
              ) : (
                <CodeGenDebugPanelClosed
                  onOpenPanel={() => setOpenCodeGenDebugPanel(true)}
                  isSuccess={codeGenDebug?.success}
                />
              )}
            </SplitPane>
          </Box>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <Box display="flex" gap={2}>
            <Button variant="contained" color="secondary" onClick={createCodeBlock} disabled={isReadOnly}>
              CREATE CODE BLOCK
            </Button>
            <Button variant="contained" color="secondary" onClick={() => generateCodeBlock()} disabled={isReadOnly}>
              GENERATE CODE BLOCK
            </Button>
          </Box>
        </Box>
      )}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth>
        <DialogTitle>Add Document Short Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Document Short Name"
            type="text"
            fullWidth
            value={newDocumentShortName}
            color="secondary"
            onChange={(e) => {
              setErrorMessage('');
              setNewDocumentShortName(e.target.value);
            }}
            disabled={isReadOnly}
            error={!!errorMessage}
          />
          {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="inherit">
            Cancel
          </Button>
          <Button color="secondary" onClick={handleAddShortNameAndGenerateCodeBlock} disabled={isReadOnly}>
            Generate
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default sizeMe({
  monitorHeight: false,
  monitorWidth: true,
  noPlaceholder: true,
})(CodeBlockGrid);
