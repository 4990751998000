import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@mui/material';
import React, { useRef } from 'react';
import Draggable from 'react-draggable';
import { useDispatch } from 'react-redux';

import { documentService } from '../../services/DocumentService';
import { deleteTest } from '../../store/test';

const PaperComponent = (props: any) => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

const boldButton = {
  fontWeight: 600,
};

type DeleteTestCaseDialogProps = {
  open: boolean;
  testCaseId: string;
  setOpen?: any;
};

const DeleteTestCaseDialog: React.FC<DeleteTestCaseDialogProps> = ({ open, setOpen, testCaseId }) => {
  const dispatch = useDispatch();
  const cancelButtonEl = useRef<HTMLButtonElement>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    await documentService.deleteTest(testCaseId);
    dispatch(deleteTest(testCaseId));
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Delete Testcase
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} ref={cancelButtonEl}>
          Cancel
        </Button>
        <Button onClick={handleDelete} color="secondary" sx={boldButton}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { DeleteTestCaseDialog };
