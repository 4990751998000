import { useLocation } from '@gatsbyjs/reach-router';
import React from 'react';

import DocumentDetails from './DocumentDetails';
import DocumentList from './DocumentList';
import ViewModeProvider from './ViewModeProvider';

const DocumentPage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const documentId = queryParams.get('document-id');

  return documentId ? (
    <ViewModeProvider>
      <DocumentDetails documentUuid={documentId} />
    </ViewModeProvider>
  ) : (
    <DocumentList />
  );
};
export default DocumentPage;
