import { Box } from '@mui/material';
import React from 'react';

// If isSuccess, return green circle, else return orange circle
const CodeGenDebugStatus = ({ isSuccess }: { isSuccess: boolean }) => {
  return (
    <Box
      sx={{
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: isSuccess ? 'green' : 'orange',
      }}
    />
  );
};

export default CodeGenDebugStatus;
