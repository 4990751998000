import Icon from '@mui/material/Icon';
import React from 'react';

import ClipboardSVG from '../../images/copy_clipboard_24px.svg';

const ClipboardIcon: React.FC = () => {
  return (
    <Icon>
      <img src={ClipboardSVG} alt="Clipboard icon" />
    </Icon>
  );
};

export { ClipboardIcon };
