import grey from '@mui/material/colors/grey';
import React, { useCallback } from 'react';
import ReactSplitPane, { SplitPaneProps } from 'react-split-pane-r17';
import styled from 'styled-components';

import { trackEvent, util } from '../../services';

const Wrapper = styled.div`
  .Resizer {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: ${grey[400]};
    opacity: 0.5;
    z-index: 1;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }

  .Resizer:hover {
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
  }

  .Resizer.horizontal {
    height: 3px;
    cursor: ${({ children = { props: { hoverDisabled: false } } }) => {
      // @ts-ignore
      return children.props.hoverDisabled ? 'default' : 'col-resize';
    }};
  }

  .Resizer.vertical {
    width: 3px;
    cursor: ${({ children = { props: { hoverDisabled: false } } }) => {
      // @ts-ignore
      return children.props.hoverDisabled ? 'default' : 'col-resize';
    }};
  }

  .Resizer.horizontal:hover {
    box-shadow: ${({ children = { props: { hoverDisabled: false } } }) => {
      // @ts-ignore
      return children.props.hoverDisabled ? 'none' : '#ffacfb 5px 5px 50px 20px inset';
    }};
  }

  .Resizer.vertical:hover {
    box-shadow: ${({ children = { props: { hoverDisabled: false } } }) => {
      // @ts-ignore
      return children.props.hoverDisabled ? 'none' : '#ffacfb 5px 5px 50px 20px inset';
    }};
  }
`;

interface CustomSplitPaneProps extends SplitPaneProps {
  hoverDisabled?: boolean;
}

const TestEditorSplitPane: React.FC<CustomSplitPaneProps> = (props) => {
  const handleDragFinished = useCallback((newSize: number) => {
    util.fireSplitPaneResizeEvent(newSize);
    trackEvent.splitPaneDrag();
    props.onDragFinished && props.onDragFinished(newSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <ReactSplitPane
        defaultSize="50%"
        style={{ minHeight: `calc(100vh - 116px)`, height: '', position: 'relative' }}
        onDragFinished={handleDragFinished}
        {...props}
      >
        {props.children}
      </ReactSplitPane>
    </Wrapper>
  );
};

export { TestEditorSplitPane };
