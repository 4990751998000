import gql from 'graphql-tag';

import { apollo } from '../apollo/client';
import { Comment, CommentCount, CommentInput } from '../types/Comment';

const CREATE_COMMENT = gql`
  mutation ($commentInput: CommentInput!) {
    createComment(commentInput: $commentInput) {
      _id
      documentId
      referenceId
      content
      isResolved
      isDeleted
      createdBy
      updatedBy
      createdAt
      updatedAt
      replies {
        _id
        referenceId
        content
        isResolved
        isDeleted
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
    }
  }
`;
const createComment = async (payload: CommentInput): Promise<Comment | undefined> => {
  const { data } = await apollo.mutate({
    mutation: CREATE_COMMENT,
    variables: {
      commentInput: payload,
    },
  });
  return data?.createComment as Comment;
};

const GET_COMMENTS_BY_REFERENCE_ID = gql`
  query GET_COMMENTS_BY_REFERENCE_ID($referenceId: ID!) {
    getCommentsByReferenceId(referenceId: $referenceId) {
      _id
      documentId
      referenceId
      content
      isResolved
      isDeleted
      createdBy
      updatedBy
      createdAt
      updatedAt
      replies {
        _id
        referenceId
        content
        isResolved
        isDeleted
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
const getCommentsByReferenceId = async ({ referenceId }: { referenceId: string }): Promise<Comment[]> => {
  const { data } = await apollo.query({
    query: GET_COMMENTS_BY_REFERENCE_ID,
    fetchPolicy: 'no-cache',
    variables: {
      referenceId,
    },
  });
  return data?.getCommentsByReferenceId as Comment[];
};

const RESOLVE_COMMENT = gql`
  mutation ($id: String!, $updatedBy: String) {
    resolveComment(id: $id, updatedBy: $updatedBy) {
      _id
      documentId
      referenceId
      content
      isResolved
      isDeleted
      createdBy
      updatedBy
      createdAt
      updatedAt
      replies {
        _id
        referenceId
        content
        isResolved
        isDeleted
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
const resolveComment = async (id: string, updatedBy: string): Promise<boolean> => {
  await apollo.mutate({
    mutation: RESOLVE_COMMENT,
    variables: {
      id,
      updatedBy,
    },
  });
  return true;
};

const DELETE_COMMENT = gql`
  mutation ($id: String!, $updatedBy: String) {
    deleteComment(id: $id, updatedBy: $updatedBy) {
      _id
      documentId
      referenceId
      content
      isResolved
      isDeleted
      createdBy
      updatedBy
      createdAt
      updatedAt
      replies {
        _id
        referenceId
        content
        isResolved
        isDeleted
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
const deleteComment = async (id: string, updatedBy: string): Promise<boolean> => {
  await apollo.mutate({
    mutation: DELETE_COMMENT,
    variables: {
      id,
      updatedBy,
    },
  });
  return true;
};

const REPLY_COMMENT = gql`
  mutation ($id: String!, $commentInput: CommentInput!) {
    replyComment(id: $id, commentInput: $commentInput) {
      _id
      documentId
      referenceId
      content
      isResolved
      isDeleted
      createdBy
      updatedBy
      createdAt
      updatedAt
      replies {
        _id
        referenceId
        content
        isResolved
        isDeleted
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
    }
  }
`;
const replyComment = async (id: string, payload: CommentInput): Promise<Comment | undefined> => {
  const { data } = await apollo.mutate({
    mutation: REPLY_COMMENT,
    variables: {
      id,
      commentInput: payload,
    },
  });
  return data?.replyComment as Comment;
};

const COUNT_COMMENTS_OF_DOCUMENT = gql`
  query ($documentId: ID!) {
    countCommentsOfDocument(documentId: $documentId) {
      comments
      resolved
    }
  }
`;
const countCommentsOfDocument = async (documentId: string): Promise<CommentCount | undefined> => {
  const { data } = await apollo.mutate({
    mutation: COUNT_COMMENTS_OF_DOCUMENT,
    variables: {
      documentId,
    },
  });
  return data?.countCommentsOfDocument;
};

const COUNT_COMMENTS_OF_SECTION = gql`
  query ($referenceId: ID!) {
    countCommentsOfSection(referenceId: $referenceId) {
      comments
      resolved
    }
  }
`;
const countCommentsOfSection = async (referenceId: string): Promise<CommentCount | undefined> => {
  const { data } = await apollo.mutate({
    mutation: COUNT_COMMENTS_OF_SECTION,
    variables: {
      referenceId,
    },
  });
  return data?.countCommentsOfSection;
};

export const commentService = {
  createComment,
  getCommentsByReferenceId,
  resolveComment,
  deleteComment,
  replyComment,
  countCommentsOfDocument,
  countCommentsOfSection,
};
