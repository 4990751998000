import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { useState } from 'react';

import useRole from '../../hooks/useRole';
import CreateEditAtomDialog from './CreateEditAtomDialog';

const CreateAtomButton = ({ codeBlockId }: { codeBlockId: string }) => {
  const [open, setOpen] = useState(false);
  const { isAdmin } = useRole();

  const onClose = async () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        fullWidth
        variant="contained"
        color="secondary"
        startIcon={<Add />}
        disabled={!isAdmin}
      >
        Create Atom
      </Button>
      <CreateEditAtomDialog codeBlockId={codeBlockId} open={open} onClose={onClose} />
    </>
  );
};

export { CreateAtomButton };
