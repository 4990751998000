import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SplitPane from 'react-split-pane-r17';

import useRole from '../../hooks/useRole';
import useUsername from '../../hooks/useUsername';
import { documentService } from '../../services/DocumentService';
import { trackEvent } from '../../services/analytics';
import { settingSelectors, updateSplit3Size } from '../../store/setting';
import { addTest, testSelectors } from '../../store/test';
import { Test } from '../../types/RealtaDocument';
import { ToolbarIconButton } from '../old-version/ui/ToolbarIconButton/ToolbarIconButton';
import { ResultsPanelClosed } from './ResultsPanelClosed';
import { TestCaseInputPanel } from './TestCaseInputPanel';
import { TestCaseResultsPanel } from './TestCaseResultsPanel';

type TestCasePanelProps = {
  tests: Test[];
  sectionId: string;
  onTestsChange?: () => void;
  onSave?: (test: Test) => void;
};

const TestCasePanel: React.FC<TestCasePanelProps> = ({ tests, sectionId, onTestsChange, onSave }) => {
  const dispatch = useDispatch();
  const { isReadOnly } = useRole();
  const username = useUsername();

  const isResultsPanelClosed = useSelector(settingSelectors.isResultsPanelClosed);
  const split3Size = useSelector(settingSelectors.split3Size);
  const selectedTestId = useSelector(testSelectors.selectedTestId);

  const split3Props: any = {
    primary: isResultsPanelClosed ? 'second' : 'first',
    allowResize: !isResultsPanelClosed,
    size: isResultsPanelClosed ? '35px' : split3Size || '50%',
    pane1Style:
      (!isResultsPanelClosed && {
        minWidth: '35%',
        maxWidth: '74%',
      }) ||
      {},
    pane2Style:
      (!isResultsPanelClosed && {
        minWidth: '25%',
        maxWidth: '65%',
      }) ||
      {},
  };

  const handleSplit3Drag = (size: number) => dispatch(updateSplit3Size(size));

  const handleSplit3DoubleClick = () => {
    !isResultsPanelClosed && dispatch(updateSplit3Size('50%'));
    trackEvent.splitPaneDoubleClick();
  };

  const handleCreateNew = async () => {
    const newTest = await documentService.createTest({
      name: 'Untitled test case',
      description: '',
      parentNode: sectionId,
      createdBy: username,
    });
    newTest && dispatch(addTest(newTest));
  };

  return tests.length > 0 ? (
    selectedTestId ? (
      <SplitPane
        split="vertical"
        {...split3Props}
        onDragFinished={handleSplit3Drag}
        onResizerDoubleClick={handleSplit3DoubleClick}
        hoverDisabled={isResultsPanelClosed}
      >
        <TestCaseInputPanel onSave={onSave} onTestsChange={onTestsChange} />
        {isResultsPanelClosed ? <ResultsPanelClosed /> : <TestCaseResultsPanel />}
      </SplitPane>
    ) : (
      <Box textAlign="center" pt={5}>
        Select a test case
      </Box>
    )
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '100px', lineHeight: '2' }}>
      <Box component="span" sx={{ fontSize: '1.25rem', fontWeight: 500 }}>
        Create new test case.
      </Box>
      <ToolbarIconButton aria-label="Create new" title="Create new" onClick={handleCreateNew} disabled={isReadOnly}>
        <AddIcon fontSize="large" />
      </ToolbarIconButton>
    </Box>
  );
};

export { TestCasePanel };
