import { navigate } from '@gatsbyjs/reach-router';
import { AppBar, Box, Button, Toolbar, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useEffect, useMemo } from 'react';
import { useAsync } from 'react-use';

import useRole from '../../hooks/useRole';
import { documentService } from '../../services/DocumentService';
import { View } from '../../types/ViewMode';
import { generateDocumentUrl } from '../../utils/document-url';
import { useChangeAlert, useViewMode } from './ViewModeProvider';

interface DocumentHeaderProps {
  documentUuid: string;
  sectionUuid: string;
}

const DocumentHeader: React.FC<DocumentHeaderProps> = ({ documentUuid, sectionUuid }) => {
  const { isAdmin, isTester, isSearcher, isCommenter } = useRole();
  const queryParams = new URLSearchParams(location.search);

  const { viewMode, setViewMode } = useViewMode();
  const { showChangeAlert, setShowChangeAlert } = useChangeAlert();

  const page = useMemo(() => {
    const page = queryParams.get('page');
    return page ? parseInt(page) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.get('page')]);

  const referenceText = useAsync(async () => {
    return await documentService.getReferenceText(sectionUuid, true, false);
  }, [sectionUuid]);

  useEffect(() => {
    const iView = queryParams.get('view');
    switch (iView) {
      case 'Comment':
        if (isAdmin) {
          setViewMode('Comment');
        } else {
          navigate('/documents');
        }
        break;
      case 'CodeBlock':
        if (isAdmin) {
          setViewMode('CodeBlock');
        } else {
          navigate('/documents');
        }
        break;
      case 'Test':
        if (isAdmin || isTester) {
          setViewMode('Test');
        } else {
          navigate('/documents');
        }
        break;
      case 'Search':
        if (isSearcher) {
          setViewMode('Search');
        } else {
          navigate('/documents');
        }
        break;
      case 'References':
      default:
        setViewMode('References');
        break;
    }
  }, [queryParams.get('view')]);

  const navigateView = (newView: View) => {
    setViewMode(newView);
    const url = generateDocumentUrl({
      documentId: documentUuid,
      sectionId: sectionUuid,
      page: page || 1,
      view: newView,
    });
    navigate(url, {
      replace: true,
    });
  };

  const handleViewChange = (newView: View) => {
    if (showChangeAlert) {
      const shouldNavigate = window.confirm('Changes you made may not be saved.');
      if (shouldNavigate) {
        setShowChangeAlert(false);
        navigateView(newView);
      }
    } else {
      navigateView(newView);
      setShowChangeAlert(false);
    }
  };

  return (
    <AppBar position="static" sx={{ bgcolor: grey[300], color: '#000', boxShadow: 'none' }}>
      <Toolbar
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 48,
          minHeight: '48px !important',
          display: 'flex',
          gap: 2,
        }}
      >
        <Tooltip title={referenceText.loading ? '' : referenceText.value!}>
          <Typography
            fontWeight="bold"
            sx={{
              flexGrow: 1,
              width: 100,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              direction: 'rtl',
              textAlign: 'end',
              whiteSpace: 'nowrap',
            }}
          >
            <Box
              component="span"
              sx={{
                direction: 'ltr',
                unicodeBidi: 'plaintext',
              }}
            >
              {referenceText.loading ? '' : referenceText.value!}
            </Box>
          </Typography>
        </Tooltip>
        <Box display="flex" gap={2} flexShrink={0}>
          {isCommenter && (
            <Button
              variant={viewMode === 'Comment' ? 'contained' : 'outlined'}
              color="secondary"
              onClick={() => handleViewChange('Comment')}
            >
              Comment
            </Button>
          )}
          <Button
            variant={viewMode === 'References' ? 'contained' : 'outlined'}
            color="secondary"
            onClick={() => handleViewChange('References')}
          >
            References
          </Button>

          {isAdmin && (
            <Button
              variant={viewMode === 'CodeBlock' ? 'contained' : 'outlined'}
              color="secondary"
              onClick={() => handleViewChange('CodeBlock')}
            >
              Code Blocks
            </Button>
          )}
          {(isAdmin || isTester) && (
            <Button
              variant={viewMode === 'Test' ? 'contained' : 'outlined'}
              color="secondary"
              onClick={() => handleViewChange('Test')}
            >
              Tests
            </Button>
          )}
          {isSearcher && (
            <Button
              variant={viewMode === 'Search' ? 'contained' : 'outlined'}
              color="secondary"
              onClick={() => handleViewChange('Search')}
            >
              Search
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default DocumentHeader;
