import { green, grey, red } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import * as _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controlled as CodeMirror, ICodeMirror } from 'react-codemirror2-react-17';
import { useSelector } from 'react-redux';

import { documentService } from '../../services/DocumentService';
import { testSelectors } from '../../store/test';
import { ResultsLineStatus } from '../../store/testCase';
import { StatusSymbol } from '../../utils/constants';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: '#ffff',
  },
  inactive: {
    backgroundColor: grey[100],
  },
});

interface RatTestCodeMirrorProps {
  value: string;
  options: ICodeMirror['options'];
  onChange?: (value: string) => {};
  statusList?: ResultsLineStatus[];
  isActive?: boolean;
  onFocus?: (event: Event) => void;
  readOnly?: boolean;
  lineWrapping: boolean;
}

const RatTestCodeMirror: React.FC<RatTestCodeMirrorProps> = ({
  value,
  options = {},
  onChange,
  statusList,
  isActive = true,
  onFocus,
  readOnly,
  lineWrapping,
}) => {
  const classes = useStyles();

  const [input, setInput] = useState(value);
  const [isFocus, setIsFocus] = useState(false);
  const [editor, setEditor] = useState<CodeMirror.Editor>();
  const autoSuggest = useSelector(testSelectors.autoSuggestions);

  useEffect(() => {
    setInput(value);
  }, [value]);

  const handleChange: any = (_editor: string, _data: string, value: string) => {
    setInput(value);
    dispatchChange(value);
  };

  const dispatchChange = (value: string) => {
    onChange && onChange(value);
  };

  const statusMarker = useCallback((status: ResultsLineStatus) => {
    const statusEl = document.createElement('span');
    statusEl.innerHTML = StatusSymbol[status];
    statusEl.style.color =
      status === ResultsLineStatus.FAIL_REQUIRED || status === ResultsLineStatus.FAIL_UNWANTED ? red[700] : green[700];
    statusEl.style.marginLeft = '1px';
    return statusEl;
  }, []);

  useEffect(() => {
    if (!editor || !statusList) {
      return;
    }

    setTimeout(() => {
      editor.clearGutter('status-marker');
      statusList.forEach((status, index) => {
        if (status === ResultsLineStatus.NA) {
          return;
        }
        editor.setGutterMarker(index, 'status-marker', statusMarker(status));
      });
    }, 50);
  }, [statusList, editor]);

  const autoSuggestHint = async (editor: CodeMirror.Editor) => {
    const cursor: CodeMirror.Position = editor.getCursor();
    const start: number = cursor.ch;
    const end: number = cursor.ch;
    const token: any = editor.getTokenAt(cursor);
    let currentWord: string = token.string;
    if (!currentWord || !currentWord.trim()) {
      return;
    }
    currentWord = currentWord.toLowerCase();
    const atomList = await documentService.getAtomTexts({ suggestedText: currentWord });
    const startPos = {
      line: cursor.line,
      ch: start - currentWord.length,
    };
    const endPos = {
      line: cursor.line,
      ch: end,
    };

    return {
      list: atomList.length ? atomList : [],
      from: startPos,
      to: endPos,
    };
  };

  const handleOnKeyup = (editor: any, event: any) => {
    const keyIgnoreList: number[] = [8, 9, 13, 16, 17, 18, 20, 27, 37, 38, 39, 40, 67, 76, 86, 88, 90, 91];
    if (!keyIgnoreList.includes(event.keyCode) && !editor.state.completionActive) {
      editor.showHint(editor, {
        hint: autoSuggestHint,
        completeSingle: false,
      });
    }
  };

  const defaultOptions = {
    lineNumbers: true,
    autoScroll: true,
    styleActiveLine: !options.readOnly && isFocus,
    mode: 'turnipMode',
    lineWrapping,
    gutters: ['status-marker', 'CodeMirror-linenumbers'],
    highlightSelectionMatches: {
      showToken: /(\w+\.\w+)|\w/,
    },
    showCursorWhenSelecting: true,
    readOnly: readOnly ? 'nocursor' : false,
    hintOptions: autoSuggest
      ? {
          hint: autoSuggestHint,
          completeSingle: false,
        }
      : null,
    extraKeys: {
      'Ctrl-Space': 'autocomplete',
    },
  };

  return (
    <CodeMirror
      className={classNames(classes.root, {
        [classes.inactive]: !isActive || readOnly,
      })}
      value={input}
      options={{ ...defaultOptions, ...options }}
      onBeforeChange={handleChange}
      onFocus={(_editor: CodeMirror.Editor, event: Event) => {
        setIsFocus(true);
        onFocus && onFocus(event);
      }}
      onBlur={() => setIsFocus(false)}
      editorDidMount={(ed) => {
        setEditor(ed);
      }}
      onKeyUp={handleOnKeyup}
    />
  );
};

export { RatTestCodeMirror };
