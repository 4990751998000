import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Box, CircularProgress, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';

import { useGetAtoms } from '../../services/queries';
import { ToolbarIconButton } from '../old-version/ui/ToolbarIconButton/ToolbarIconButton';
import AtomItem from './AtomItem';
import { CreateAtomButton } from './CreateAtomButton';

const useStyles = makeStyles({
  resultsPanel: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
});

const AtomPanel = ({ onClosePanel, codeBlockId }: { codeBlockId: string; onClosePanel: () => void }) => {
  const classes = useStyles();
  const controls = useAnimation();
  const { data: atoms, isFetching } = useGetAtoms(codeBlockId);

  useEffect(() => {
    controls.set({ opacity: 0 });
    controls.start({ opacity: 1, transition: { duration: 2 } });
  }, []);

  const handleCollapse = () => {
    onClosePanel();
  };

  return (
    <>
      <motion.article className={classes.resultsPanel} animate={controls}>
        <AppBar position="static" color="inherit" sx={{ backgroundColor: 'grey.200', zIndex: 10 }}>
          <Toolbar variant="dense" disableGutters={true}>
            <Typography fontWeight={600} sx={{ flexGrow: 1, pl: 1 }}>
              Atoms
            </Typography>
            <div>
              <ToolbarIconButton aria-label="close results panel" onClick={handleCollapse} title="Close">
                <CloseIcon fontSize="small" />
              </ToolbarIconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Box p={1} flexGrow={1} display="flex" flexDirection="column" gap={2} height="1px" sx={{ overflowY: 'auto' }}>
          {isFetching && <CircularProgress size="sm" color="secondary" sx={{ mx: 'auto', display: 'block' }} />}
          {atoms?.map((atom) => <AtomItem key={atom.uuid} atom={atom} codeBlockId={codeBlockId} />)}
        </Box>
        <Box
          p={1}
          boxShadow="0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);"
        >
          <CreateAtomButton codeBlockId={codeBlockId} />
        </Box>
      </motion.article>
    </>
  );
};

export { AtomPanel };
