import { navigate } from '@gatsbyjs/reach-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import * as yup from 'yup';

import useRole from '../../hooks/useRole';
import { documentService } from '../../services/DocumentService';
import { RealtaDocument } from '../../types/RealtaDocument';
import { getMsalInstance } from '../../utils/get-msal-instance';
import DocumentListFolder from './DocumentListFolder';
import DocumentListItem from './DocumentListItem';

const schema = () =>
  yup.object().shape({
    datasets: yup.array().of(yup.string()),
    jdxs: yup.array().of(yup.string()),
    title: yup.string(),
  });

const useStyles = makeStyles(() => ({
  formControl: {
    width: 300,
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));

type DataInterface = {
  datasets: string[];
  jdxs: string[];
  title: string;
};

const DocumentList: React.FC<{}> = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { isCommenter } = useRole();
  const msalInstance = getMsalInstance();
  const queryParams = new URLSearchParams(location.search);

  const [documents, setDocuments] = useState<RealtaDocument[]>([]);
  const [filteredDocuments, setFilteredDocuments] = useState<RealtaDocument[]>([]);
  const [loadComment, setLoadComment] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [jurisdictions, setJurisdictions] = React.useState<string[]>([]);
  const [datasets, setDatasets] = React.useState<string[]>([]);

  const { handleSubmit, errors, register, control, setValue, watch } = useForm<{
    datasets: string[];
    jdxs: string[];
    title: string;
  }>({
    defaultValues: {
      datasets: [],
      jdxs: [],
      title: '',
    },
    resolver: yupResolver(schema()),
  });

  const isAllJdxsSelected = watch('jdxs').length > 0 && watch('jdxs').length === jurisdictions.length;
  const isAllDatasetSelected = watch('datasets').length > 0 && watch('datasets').length === datasets.length;

  useEffect(() => {
    const init = async () => {
      try {
        setIsLoading(true);

        const title = queryParams.get('title') || undefined;
        if (title) setValue('title', title);

        const account = msalInstance.getActiveAccount();

        const selectedDatasets = queryParams.get('datasets')?.split(',');
        if (selectedDatasets && selectedDatasets.length > 0) {
          setValue('datasets', selectedDatasets);
        }

        const [dat, jdxs] = await Promise.all([
          documentService.getDatasets(account?.idTokenClaims?.roles),
          documentService.getJurisdictions(selectedDatasets),
        ]);

        setDatasets(dat.map((j) => j.data));
        if (!selectedDatasets || selectedDatasets.length === 0) {
          setValue(
            'datasets',
            dat.map((j) => j.data)
          );
        }

        const formattedJurisdictions = jdxs.map((j) => j.data);
        setJurisdictions(formattedJurisdictions);

        const selectedJdxs =
          queryParams
            .get('jdxs')
            ?.split(',')
            .filter((jdx) => jdx && formattedJurisdictions.includes(jdx)) || undefined;
        if (selectedJdxs && selectedJdxs.length > 0) {
          setValue('jdxs', selectedJdxs);
        } else {
          setValue(
            'jdxs',
            jdxs.map((j) => j.data)
          );
        }

        const docs = await documentService.getAllDocumentMeta({
          roles: account?.idTokenClaims?.roles,
          title,
          jurisdictions: selectedJdxs,
          datasets: selectedDatasets,
        });
        setDocuments(docs);
        setFilteredDocuments(docs);
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Failed to fetch documents', { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    };

    init();
  }, [queryParams.get('jdxs'), queryParams.get('title'), queryParams.get('datasets')]);

  const handleSearch = async (data: DataInterface) => {
    try {
      const url = new URL('/documents', window.location.origin);

      const searchParams = new URLSearchParams(window.location.search);

      if (data.datasets.length > 0) {
        searchParams.set('datasets', data.datasets.join(','));
      } else {
        searchParams.delete('datasets');
      }

      if (data.jdxs.length > 0) {
        searchParams.set('jdxs', data.jdxs.join(','));
      } else {
        searchParams.delete('jdxs');
      }

      if (data.title) {
        searchParams.set('title', data.title);
      } else {
        searchParams.delete('title');
      }

      url.search = searchParams.toString();
      navigate(url.toString());
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to search documents', { variant: 'error' });
    }
  };

  const handleChangeTitle = (title: string) => {
    setFilteredDocuments(() =>
      title ? documents.filter((doc) => doc.displayName?.toLowerCase().includes(title.toLowerCase())) : documents
    );
  };

  return (
    <Box>
      <Box bgcolor="#ddd" px={2} py={1} mb={2} display="flex" gap={2} alignItems="center">
        {isCommenter && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: '12px !important',
              alignItems: 'center',
              flexShrink: 0,
              width: 'fit-content',
            }}
          >
            <Switch
              size="small"
              checked={loadComment}
              onChange={() => setLoadComment(!loadComment)}
              color="secondary"
            />
            <Typography fontSize={10} whiteSpace="nowrap">
              Show Comments
            </Typography>
          </Box>
        )}
        <Box display="flex" flexGrow={1} gap={2} component="form" onSubmit={handleSubmit(handleSearch)}>
          <Grid container spacing={2} width="100%">
            <Grid item xs={4} md={4}>
              <FormControl sx={{ width: '100%' }} color="secondary">
                <InputLabel id="datasets">Datasets</InputLabel>
                <Controller
                  control={control}
                  name="datasets"
                  render={({ onChange, ...fields }) => (
                    <Select
                      {...fields}
                      labelId="datasets"
                      id="datasets-select"
                      multiple
                      renderValue={(selected: any) => selected.join(', ')}
                      error={!!errors.datasets}
                      input={<OutlinedInput label="Datasets" />}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value[value.length - 1] === 'all') {
                          setValue('datasets', watch('datasets').length === datasets.length ? [] : datasets);
                          return;
                        }
                        onChange(e);
                      }}
                    >
                      <MenuItem
                        value="all"
                        classes={{
                          root: isAllDatasetSelected ? classes.selectedAll : '',
                        }}
                        color="secondary"
                      >
                        <ListItemIcon>
                          <Checkbox
                            classes={{ indeterminate: classes.indeterminateColor }}
                            checked={isAllDatasetSelected}
                            indeterminate={watch('datasets').length > 0 && watch('datasets').length < datasets.length}
                            color="secondary"
                          />
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
                      </MenuItem>
                      {datasets.map((dataset) => (
                        <MenuItem key={dataset} value={dataset}>
                          <Checkbox checked={watch('datasets').includes(dataset)} color="secondary" />
                          <ListItemText primary={dataset} />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} md={4}>
              <FormControl sx={{ width: '100%' }} color="secondary">
                <InputLabel id="jdxs">Jurisdictions</InputLabel>
                <Controller
                  control={control}
                  name="jdxs"
                  render={({ onChange, ...fields }) => (
                    <Select
                      {...fields}
                      labelId="jdxs"
                      id="jdxs-select"
                      multiple
                      renderValue={(selected: any) => selected.join(', ')}
                      error={!!errors.jdxs}
                      input={<OutlinedInput label="Jurisdictions" />}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value[value.length - 1] === 'all') {
                          setValue('jdxs', watch('jdxs').length === jurisdictions.length ? [] : jurisdictions);
                          return;
                        }
                        onChange(e);
                      }}
                    >
                      <MenuItem
                        value="all"
                        classes={{
                          root: isAllJdxsSelected ? classes.selectedAll : '',
                        }}
                        color="secondary"
                      >
                        <ListItemIcon>
                          <Checkbox
                            classes={{ indeterminate: classes.indeterminateColor }}
                            checked={isAllJdxsSelected}
                            indeterminate={watch('jdxs').length > 0 && watch('jdxs').length < jurisdictions.length}
                            color="secondary"
                          />
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
                      </MenuItem>
                      {jurisdictions.map((jurisdiction) => (
                        <MenuItem key={jurisdiction} value={jurisdiction}>
                          <Checkbox checked={watch('jdxs').includes(jurisdiction)} color="secondary" />
                          <ListItemText primary={jurisdiction} />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} md={4}>
              <Controller
                control={control}
                name="title"
                render={({ onChange, ...fields }) => (
                  <TextField
                    {...fields}
                    label="Document Title"
                    fullWidth
                    color="secondary"
                    inputRef={register}
                    onChange={(e) => {
                      onChange(e);
                      handleChangeTitle(e.target.value);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button color="secondary" variant="contained" type="submit" sx={{ height: 56 }}>
            Filter
          </Button>
        </Box>
      </Box>
      {isLoading ? (
        <Box width="100%" height={400} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size={80} color="secondary" />
        </Box>
      ) : (
        <>
          {filteredDocuments.map((document) =>
            document.createdBy === 'document-collection' ? (
              <DocumentListFolder key={document.uuid} document={document} loadComment={loadComment} />
            ) : (
              <DocumentListItem key={document.uuid} document={document} loadComment={loadComment} />
            )
          )}
        </>
      )}
    </Box>
  );
};
export default DocumentList;
