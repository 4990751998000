import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { testSelectors, updateTest } from '../../store/test';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    resize: 'none',
    border: 'none',
    outlineOffset: '0px',
    outline: 'none',
    display: 'inline-block',
    padding: '3px 8px',
  },
});

type StartNodeInputProps = {
  hidden: boolean;
  isReadOnly?: boolean;
  onChange?: () => void;
};

const StartNodeInput: React.FC<StartNodeInputProps> = ({ hidden, isReadOnly, onChange }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const selectedTest = useSelector(testSelectors.selectedTest);
  const startNode = selectedTest?.startNode || '';
  const [value, setValue] = useState(startNode);

  useEffect(() => {
    setValue(startNode);
  }, [startNode]);

  const dispatchChange = (value: string) => selectedTest && dispatch(updateTest({ ...selectedTest, startNode: value }));

  const handleChange: any = (event: any) => {
    setValue(event.target.value);
    dispatchChange(event.target.value);
    onChange && onChange();
  };

  return (
    <>
      {!hidden && (
        <textarea
          placeholder={isReadOnly ? '' : 'type start node'}
          onChange={handleChange}
          value={value}
          className={classNames(classes.root)}
          rows={5}
          disabled={isReadOnly}
        />
      )}
    </>
  );
};

export { StartNodeInput };
