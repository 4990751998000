import { Box, Card, CircularProgress, Typography } from '@mui/material';
import React from 'react';

import { useGetSectionReferences } from '../../services/queries';
import { DocumentNode } from '../../types/RealtaDocument';
import ReferenceItem from './ReferenceItem';

const References: React.FC<{
  documentNode: DocumentNode;
}> = ({ documentNode }) => {
  const { data: references, isLoading } = useGetSectionReferences(documentNode.uuid, false, [documentNode.uuid]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" py={1}>
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return references?.length ? (
    <Card sx={{ p: 2, mx: 1, mt: 1, mb: 2 }}>
      <Typography variant="h6" fontWeight="bold" mb={2}>
        References
      </Typography>
      <Box display="flex" flexDirection="column" gap={1}>
        {references.map((reference, i) => (
          <ReferenceItem
            key={reference.section.uuid}
            data={reference}
            position={i + 1}
            upstream={false}
            rootNodeIds={[documentNode.uuid, reference.section.uuid || '']}
          />
        ))}
      </Box>
    </Card>
  ) : (
    <></>
  );
};
export default References;
