import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { testSelectors, updateTest } from '../../store/test';
import { RatTestCodeMirror } from './RatTestCodeMirror';

const UnwantedResultsInput: React.FC<{ isReadOnly?: boolean; onChange?: () => void }> = ({ isReadOnly, onChange }) => {
  const dispatch = useDispatch();
  const selectedTest = useSelector(testSelectors.selectedTest);
  const wordWrap = useSelector(testSelectors.wordWrap);
  const unwantedResults = selectedTest?.unwantedResults || '';
  const [value, setValue] = useState(unwantedResults);

  useEffect(() => {
    setValue(unwantedResults);
  }, [unwantedResults]);

  const dispatchChange = (value: string) =>
    selectedTest && dispatch(updateTest({ ...selectedTest, unwantedResults: value }));

  const handleChange: any = (value: any) => {
    setValue(value);
    dispatchChange(value);
    onChange && onChange();
  };

  return (
    <RatTestCodeMirror
      value={value}
      onChange={handleChange}
      options={{
        placeholder: 'type unwanted results',
      }}
      readOnly={isReadOnly}
      lineWrapping={wordWrap}
    />
  );
};

export { UnwantedResultsInput };
